// $primary: #fc6ac2;
// $secondary: white;
// $accent: #fa32aa;
// $background-color: #fceef6;
// $background-accent: #ffdff1;
// $button-bg-color: #f86cc0;
// $button-bg-hover-color: #fc4ab5;
// $text-color: rgba(87, 87, 87, 0.9);
$primary: #13216a;
$primary-transparent: #13226acc;
$secondary: white;
$accent: #172988;
$background-color: #ffffff;
$background-accent: #13216a;
$secondary-bg-color: #13216a;
$button-bg-color: #13216a;
$button-bg-hover-color: #172988;
$text-color: rgba(51, 51, 51, 0.9);
